import axios from "axios";
import { API_POLLYA_ROOT } from "../constants";
import { _dispatch, getSessionItem } from "../utilities";
export { _dispatch };

export function fetchPollList(params, pageNumber, limit, order) {
  const token = getSessionItem('token');
    return axios({
    method: "GET",
    params: {
      ...params,
      limit,
      offset: limit * (pageNumber - 1)
    },
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/list_poll`,
  });
}

export function removePoll(data, role) {
    const token = getSessionItem('token');
    return axios({
      method: "DELETE",
      data: data,
      headers: { Authorization: `Bearer ${token}`},
      url: `${API_POLLYA_ROOT}/delete_poll`,
    });
}

export function getPollDetails(pollId) {
  const token = getSessionItem('token');
  return axios({
    method: "GET",
    // data: { poll_id: pollId },
    headers: { Authorization: `Bearer ${token}`},
    params: { poll_id: pollId },
    url: `${API_POLLYA_ROOT}/view_poll`,
  });
}

export function editPoll(data) {
  const token = getSessionItem('token');
  return axios({
    method: "POST",
    data,
    // data: { poll_id: pollId },
    headers: { Authorization: `Bearer ${token}`},
    url: `${API_POLLYA_ROOT}/edit_poll`,
  });
}
