import axios from "axios";
import { API_POLLYA_ROOT, API_ROOT } from "../constants";
import { _dispatch, getSessionItem } from "../utilities";
export { _dispatch };

const ROOT_URL = API_ROOT;

export function login(data) {
  return axios({
    method: "POST",
    data: data,
    url: `${API_POLLYA_ROOT}/login`,
  });
}

export function verifyCode(data) {
  return axios({
    method: "POST",
    data: data,
    url: `${ROOT_URL}/admin/verify`,
  });
}

export function fetchUsersList(params, pageNumber, limit, isCsv) {
  return axios({
    method: "GET",
    params: {
      ...params,
      limit,
      skip: limit * (pageNumber - 1),
      isCsv: isCsv ? isCsv : false
    },
    url: `${ROOT_URL}/admin/list-users`,
  });
}

export function fetchAdminsList(params, pageNumber, limit, isCsv) {
  const token = getSessionItem('token');
    return axios({
    method: "GET",
    // params: {
    //   ...params,
    //   limit,
    //   skip: limit * (pageNumber - 1),
    //   isCsv: isCsv ? isCsv : false,
    // },
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/view_user`,
  });
}

export function fetchappuserList(params, pageNumber, limit, isCsv) {
  const token = getSessionItem('token');
    return axios({
    method: "GET",
    params: {
      ...params,
      per_page: limit,
      page: pageNumber,
      // skip: limit * (pageNumber - 1),
      isCsv: isCsv ? isCsv : false,
    },
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/list_users_adminpanel`,
  });
};

export function fetchSearchUserList(params, pageNumber, limit) {
  const token = getSessionItem('token');
    return axios({
    method: "GET",
    params: {
      ...params,
      per_page: limit,
      page: pageNumber
    },
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/user/search`,
  });
}

export function fetchPanUserList(params, pageNumber, limit, isCsv) {
  console.log("pa", limit);
  return axios({
    method: "GET",
    params: {
      ...params,
      limit,
      skip: limit * (pageNumber - 1),
      isCsv: isCsv ? isCsv : false,
    },
    url: `${ROOT_URL}/admin/list-pan-teacher`,
  });
}

export function fetchUserDetail(userId) {
  return axios({
    method: "GET",
    params: { userId },
    url: `${ROOT_URL}/admin/get-user-by-id`,
  });
}

export function uploadProfileImage(content) {
	const user = getSessionItem('user');
  return axios({
      method: 'POST',
      data: content,
      headers: {Authorization: `Bearer ${user.token}`},
      url: `${ROOT_URL}/user/upload/images-by-id`,
  });
}

export function fetchTimeZones() {
  return axios({
    method: "GET",
    url: `${ROOT_URL}/auxiliary/timezones`,
  });
}

export function getPlacesList(params) {
  const user = getSessionItem('user');
  return axios({
      method: 'GET',
      params,
      headers: {Authorization: `Bearer ${user.token}`},
      url: `${ROOT_URL}/get-place-list`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function fetchInterests(params) {
  const user = getSessionItem('user');
  return axios({
      method: 'GET',
      params,
      headers: {Authorization: `Bearer ${user.token}`},
      url: `${ROOT_URL}/auxiliary/categories-list`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function fetchLanguages() {
  return axios({
    method: "GET",
    url: `${ROOT_URL}/auxiliary/languages`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
    console.log(err);
  });
}

export function updateTeacherProfile(values) {
  const user = getSessionItem('user');
  return axios({
    method: 'PUT',
    data: values,
    headers: { Authorization: `Bearer ${user.token}` },
    url: `${ROOT_URL}/user/teacher/update`,
  });
}

export function blockIntantClass(instantClassId, blockStatus) {
  return axios({
    method: "POST",
    data: { instantClassId: `${instantClassId}`, blockStatus: blockStatus },
    url: `${ROOT_URL}/admin/classes/block`,
  });
}

export function blockScheduleClass(scheduleClassId, blockStatus) {
  return axios({
    method: "POST",
    data: { scheduleClassId: `${scheduleClassId}`, blockStatus: blockStatus },
    url: `${ROOT_URL}/admin/classes-schedule/block`,
  });
}

export function changeStudentFeeStateForClass(data) {
  return axios({
    method: "POST",
    data,
    url: `${ROOT_URL}/admin/user/class-student-fee`,
  });
}

export function changeFastFillingStateForClass(data) {
  return axios({
    method: "POST",
    data,
    url: `${ROOT_URL}/admin/user/class-fast-filling`,
  });
}

export function activateUser(userId) {
  return axios({
    method: "POST",
    data: { userId: `${userId}` },
    url: `${ROOT_URL}/admin/user/activate`,
  });
}

export function uploadVideos($this, payload) {
  return axios({
    method: 'POST',
    data: payload,
    url: `${ROOT_URL}/user/upload/videos`,
  });
}

export function deactivateUser(userId) {
  return axios({
    method: "POST",
    data: { userId: `${userId}` },
    url: `${ROOT_URL}/admin/user/deactivate`,
  });
}

export function changeStudentFeeStateForUser(data) {
  return axios({
    method: "POST",
    data,
    url: `${ROOT_URL}/admin/user/user-student-fee`,
  });
}

export function changeManager(data) {
  return axios({
    method: "POST",
    data: data,
    url: `${ROOT_URL}/admin/change-role`,
  });
}

export function changePermission(data) {
  return axios({
    method: "PUT",
    data: data,
    url: `${API_POLLYA_ROOT}/change_role`,
    headers: { 
      Authorization: `Bearer ${getSessionItem('token')}`,
      "x-user-role": "admin"
    },
  });
}

export function ResetPasswordByAdmin(data) {
  return axios({
    method: "POST",
    data: data,
    url: `${ROOT_URL}/admin/reset-password-by-admin`,
  });
}

export function createAdmin(data) {
  const token = getSessionItem('token');
  return axios({
    method: "Post",
    data: data,
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/register`,
  });
}

export function createUser(data) {
  const token = getSessionItem('token');
  return axios({
    method: "Post",
    data: data,
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/add_internal_user`,
  });
}

export function updateUserPassword(data) {
  const token = getSessionItem('token');
  return axios({
    method: "Post",
    data: data,
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/update_internal_user_password`,
  });
}

export function ResetPasswordExternal(data) {
  const token = getSessionItem('token');
  return axios({
    method: "Post",
    data: data,
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/reset_external_password`,
  });
}

export function removeAdmin(data, role) {
  const token = getSessionItem('token');
  return axios({
    method: "DELETE",
    data: data,
    headers: { Authorization: `Bearer ${token}`, "x-user-role": role },
    url: `${API_POLLYA_ROOT}/delete_user`,
  });
}

export function removeuser(data, role) {
  const token = getSessionItem('token');
  return axios({
    method: "DELETE",
    data: data,
    headers: { Authorization: `Bearer ${token}`, "x-user-role": role },
    url: `${API_POLLYA_ROOT}/delete_app_user`,
  });
}

export function suspenduser(data, role) {
  const token = getSessionItem('token');
  return axios({
    method: "POST",
    data: data,
    headers: { Authorization: `Bearer ${token}`},
    url: `${API_POLLYA_ROOT}/suspend_user`,
  });
}

export function reactivateUser(data, role) {
  const token = getSessionItem('token');
  return axios({
    method: "POST",
    data: data,
    headers: { Authorization: `Bearer ${token}`},
    url: `${API_POLLYA_ROOT}/reactivate`,
  });
}

export function getUserDetails(data) {
  return axios({
    method: "POST",
    data,
    headers: { 
      Authorization: `Bearer ${getSessionItem('token')}`
    },
    url: `${API_POLLYA_ROOT}/user_role`,
  });
}

export function createRole(data) {
  const token = getSessionItem('token');
  return axios({
    method: "Post",
    data: data,
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/addrole`,
  });
}

export function fetchRoleList() {
  return axios({
    method: "GET",
    headers: { 
      Authorization: `Bearer ${getSessionItem('token')}`
    },
    url: `${API_POLLYA_ROOT}/getrole`,
  });
}

export function removeRole(data) {
  const token = getSessionItem('token');
  return axios({
    method: "DELETE",
    data: data,
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/delete_role`,
  });
}

export function rejectPanTeacher(data) {
  return axios({
    method: "PUT",
    data: data,
    url: `${ROOT_URL}/user/teacher/reject-pan-teacher-info`,
  });
}

export function approvedPanTeacher(data) {
  return axios({
    method: "PUT",
    data: data,
    url: `${ROOT_URL}/user/teacher/approved-pan-teacher-info`,
  });
}

export function resetQR(data) {
  return axios({
    method: "PUT",
    data: data,
    url: `${ROOT_URL}/admin/reset-QR-code`,
  });
}

export function sendNotifyResetPW(data) {
  return axios({
    method: "POST",
    data: data,
    url: `${ROOT_URL}/admin/change-password`,
  });
}

export function resetPassword(resetToken, password) {
  return axios({
    method: 'POST',
    data: { password },
    url: `${ROOT_URL}/admin/change-password/${resetToken}`,
  }).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
}

export function deleteUserByAdmin(userId) {
  return axios({
    method: "DELETE",
    data: { userId: `${userId}` },
    url: `${ROOT_URL}/admin/user/delete`,
  });
}

export function ChangePass(data) {
	const token = getSessionItem('token');

  return axios({
    method: "POST",
    data: data,
    headers:
      { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/change_password`,
  });
}

export function sendWhatsappMessage(requestData) {
  return axios({
    method: "POST",
    data: requestData,
    url: `${ROOT_URL}/admin/send/direct-whatsapp-message`,
  });
}

export function sendTemplateWhatsappMessage(requestData) {
  return axios({
    method: "POST",
    data: requestData,
    url: `${ROOT_URL}/admin/send/template-whatsapp-message`,
  }).catch((error) => {
    if (error.response.status === 401) {
      window.location = "/logout";
    } else if(error.response.status === 400) {
      return error.response;
    } else if(error.response.status === 500) {
      return error.response;
    }
  });
}

export function fetchWhatsappMsgList(params) {
  return axios({
    method: "GET",
    params: {
      ...params,
      skip: params.limit * (params.pageNumber - 1),
      isCsv: params.isCsv ? params.isCsv : false,
    },
    url: `${ROOT_URL}/admin/list/whatsapp-message`,
  });
}

export function fetchWhatsappMsgTemplateList() {
  return axios({
    method: "GET",
    url: `${ROOT_URL}/admin/list/whatsapp-template`,
  });
}

export function fetchTeacherInstituteUser(params, pageNumber, limit, isCsv) {
  return axios({
    method: "GET",
    params: {
      ...params,
      limit,
      skip: limit * (pageNumber - 1),
      isCsv: isCsv ? isCsv : false
    },
    url: `${ROOT_URL}/admin/list-teacher-institute-users`,
  });
}

export function getTeacherInstituteByUserId(userId) {
  return axios({
    method: "GET",
    params: { userId },
    url: `${ROOT_URL}/teacher-institute-user/get-by-id`,
  });
};

export function updateUserDetails(data) {
  const token = getSessionItem('token');
  return axios({
    method: "POST",
    data: data,
    headers: { Authorization: `Bearer ${token}` },
    url: `${API_POLLYA_ROOT}/update_user`,
  });
}

